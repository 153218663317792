import React, { useContext, useState } from 'react'
import MenuItem from './MenuItem'
import SideMenuContext from './SideMenuContext'
import { Collapse } from '@mui/material'
import { SubMenuExpandIcon } from './SubMenuExpandIcon'

interface Props {
  children?: React.ReactNode
  icon?: React.ReactNode
  text: string
}

export default function GroupMenuItem({ icon, text, children }: Props) {
  const { open, setOpen } = useContext(SideMenuContext)
  const [expand, setExpand] = useState(false)
  return (
    <div>
      <MenuItem
        icon={icon}
        active={false}
        text={text}
        onClick={() => {
          setOpen(true)
          setExpand(!expand)
        }}
        accessoryIcon={<SubMenuExpandIcon open={expand} />}
      />
      <Collapse in={expand && open} timeout="auto" unmountOnExit>
        <div className="sub-menu">{children}</div>
      </Collapse>
    </div>
  )
}
