import React from 'react'

interface SideMenuState {
  open: boolean
  setOpen: (value: boolean) => void
}

const SideMenuContext = React.createContext<SideMenuState>({
  open: false,
  setOpen: () => {},
})

export default SideMenuContext
