import {
  Box,
  ClickAwayListener,
  Drawer,
  List,
  Stack,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { ExpandIcon } from './ExpandIcon'
import SideMenuContext from './SideMenuContext'

interface Props {
  children: React.ReactNode
  appIcon: React.ReactNode
  appName: string
  env: string
  appVersion: string
  newFont?: boolean
}

export default function SideMenu({
  children,
  appIcon,
  appName,
  env,
  appVersion,
  newFont = false,
}: Props) {
  const [open, setOpen] = useState(false)

  return (
    <SideMenuContext.Provider value={{ open, setOpen }}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SideMenuContainer>
          <AppDrawer
            sx={{
              ' .MuiTypography-root': {
                fontFamily: newFont ? 'Inter' : 'Work Sans, sans-serif',
              },
            }}
            variant="permanent"
            open={open}
            className={open ? 'AppDrawer-open' : 'AppDrawer-close'}
          >
            <ExpandButton open={open} onClick={() => setOpen(!open)}>
              <ExpandIcon />
            </ExpandButton>
            <MenuList>
              <Stack spacing={1}>{children}</Stack>

              <Box flexGrow={1} onClick={() => setOpen(!open)} />
              <Stack
                alignItems="center"
                gap="8px"
                height="84px"
                onClick={() => setOpen(!open)}
              >
                <AppIcon>{appIcon}</AppIcon>
                <Stack alignItems="center">
                  <Typography
                    variant="caption"
                    textAlign="center"
                    color={(theme) => theme.palette.text.secondary}
                    lineHeight="16px"
                    fontSize="12px"
                    letterSpacing="0.25px"
                  >
                    {appName}
                  </Typography>
                  <Typography
                    textAlign="center"
                    color={(theme) => theme.palette.text.primary}
                    fontSize="10px"
                    fontWeight="700"
                    lineHeight="16px"
                    letterSpacing="0.25px"
                  >
                    {env}
                  </Typography>
                  <Typography
                    variant="overline"
                    letterSpacing="0.25px"
                    lineHeight="16px"
                    textAlign="center"
                    color={(theme) => theme.palette.text.secondary}
                  >
                    {appVersion}
                  </Typography>
                </Stack>
              </Stack>
            </MenuList>
          </AppDrawer>
          <BackDrop open={open} onClick={() => setOpen(false)} />
        </SideMenuContainer>
      </ClickAwayListener>
    </SideMenuContext.Provider>
  )
}

const SideMenuContainer = styled('div')(({ theme }) => ({
  width: 'var(--menu-width, 95px)',
}))

const AppIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 28,
  height: 28,
}))

const BackDrop = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 100,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  transitionDuration: '0.3s',
  display: open ? 'block' : 'none',
}))

const ExpandButton = styled('button')<{ open: boolean }>(({ theme, open }) => ({
  'position': 'fixed',
  'top': 102,
  'left': open ? 205 : 80,
  'width': 30,
  'height': 30,
  'zIndex': 100,
  'paddingLeft': 15,
  'borderRadius': '50%',
  'transitionDuration': '0.3s',
  'backgroundColor': '#fff',
  'border': '1px solid rgba(32, 32, 32, 0.1)',
  'borderWidth': '0 1px 0px 0',
  'cursor': 'pointer',
  '& .expandIcon': {
    transform: open ? 'scaleX(-1.0)' : 'scaleX(1.0)',
    transitionDuration: '0.3s',
  },
}))

const MenuList = styled(List)(({ theme }) => ({
  'minHeight': '100%',
  // default color #202020
  '.MuiListItemIcon-root > img': {
    filter:
      'invert(0%) sepia(1%) saturate(7241%) hue-rotate(197deg) brightness(82%) contrast(75%)',
  },
}))

const AppDrawer = styled(Drawer)(({ theme, open }) => ({
  'position': open ? 'fixed' : 'relative',
  'zIndex': 101,
  'display': 'static',
  'transitionDuration': '0.3s',

  '&.AppDrawer-close .subMenu-expandIcon': {
    display: 'none',
  },

  '& .MuiPaper-root': {
    'zIndex': 101,
    'top': 75,
    'height': 'calc(100vh - 75px)',
    'paddingBottom': 38,
    '&:hover': {
      '& svg path': {
        fill: theme.palette.text.primary,
      },
    },
    'overflowX': 'hidden',
  },

  '& svg path': {
    fill: theme.palette.text.primary,
  },
  '& .MuiList-root': {
    transitionDuration: '0.3s',
    width: open
      ? 'var(--expanded-menu-width, 220px)'
      : 'var(--menu-width, 95px)',
    padding: 6,
  },
  '&.AppDrawer-close': {
    '& .MuiListItemText-root': {
      display: 'none',
      transitionDuration: '0.3s',
    },
  },

  '& .MuiListItemText-root': {
    color: theme.palette.text.primary,
    flexGrow: 1,
  },

  '& .MuiListItem-root .MuiListItemIcon-root': {
    minWidth: 50,
    display: 'flex',
    justifyContent: 'center',
  },

  '& .MuiListItem-root': {
    'display': 'flex',
    'height': 71,
    'cursor': 'pointer',
    'borderRadius': 8,
    // hover on the menu item, or active
    '&:hover, &.Mui-active': {
      '& .MuiTypography-body2': {
        color: theme.palette.primary.main,
      },
      '& svg path': {
        fill: theme.palette.primary.main,
      },
      '.MuiListItemIcon-root > img': {
        filter:
          'invert(38%) sepia(96%) saturate(1014%) hue-rotate(199deg) brightness(91%) contrast(95%)',
      },
    },
    '&:hover': {
      backgroundColor: '#EFF7FF',
    },

    '&.Mui-active': {
      'backgroundColor': theme.palette.primary.light,
      '& .MuiTypography-body2': {
        color: theme.palette.primary.main,
      },

      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },

  '& .sub-menu .MuiListItem-root': {
    height: 38,
  },

  '& .sub-menu .sub-menu .MuiListItem-root': {
    paddingLeft: 40,
  },
}))
