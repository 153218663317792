import React from 'react'
import UBOThemeProvider from 'ui/src/mui/UBOThemeProvider'
import { BrowserRouter } from 'react-router-dom'
import { Sidebar, SidebarProps } from 'ui'
import CachingProvider from 'ui/src/components/wrappers/CachingProvider'

export default function Root({ props }: { props: SidebarProps }) {
  const { nonce } = props

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <CachingProvider nonce={nonce}>
        <UBOThemeProvider>
          <BrowserRouter>
            <Sidebar {...props} />
          </BrowserRouter>
        </UBOThemeProvider>
      </CachingProvider>
    </React.Suspense>
  )
}
