import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext } from 'react'
import SideMenuContext from './SideMenuContext'

interface Props {
  children?: React.ReactNode
  icon?: React.ReactNode
  accessoryIcon?: React.ReactNode
  text: string
  active?: boolean
  onClick?(): void
}

export default function MenuItem({
  icon,
  text,
  active,
  onClick,
  accessoryIcon,
}: Props) {
  const { open, setOpen } = useContext(SideMenuContext)

  return (
    <Tooltip title={open ? '' : text} placement="right">
      <ListItem
        className={active ? 'Mui-active' : 'Mui-inactive'}
        onClick={() => {
          setOpen(false)
          onClick?.()
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Typography variant="body2">{text}</Typography>
        </ListItemText>
        {accessoryIcon}
      </ListItem>
    </Tooltip>
  )
}
