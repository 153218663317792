export function ExpandIcon() {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="expandIcon"
    >
      <path
        d="M1.086 1.12005C0.909044 0.932691 0.917477 0.637355 1.10484 0.460399C1.29219 0.283443 1.58753 0.291876 1.76448 0.479235L5.73101 4.67893C5.90085 4.85876 5.90086 5.13988 5.73103 5.31972L1.76451 9.52009C1.58757 9.70746 1.29223 9.71592 1.10486 9.53898C0.91749 9.36203 0.909033 9.0667 1.08597 8.87933L4.74993 4.99936L1.086 1.12005Z"
        fill="#202020"
      />
    </svg>
  )
}
