import React from 'react'
import MenuItem from './MenuItem'
import { useNavigate } from 'react-router-dom'

interface Props {
  icon: React.ReactNode
  text: string
  href: string
  children?: React.ReactNode
  reload?: boolean
  active?: () => boolean
  target?: string
}

export default function LinkMenuItem({
  icon,
  text,
  href,
  children,
  reload,
  active,
  target,
}: Props) {
  let isActive = false

  if (typeof active === 'function') {
    isActive = active()
  } else {
    isActive = window.location.pathname === href
  }
  const navigate = useNavigate()

  return (
    <MenuItem
      icon={icon}
      active={isActive}
      text={text}
      onClick={() => {
        if (target) {
          return window.open(href, target)
        }
        if (reload) {
          return window.location.replace(href)
        }
        navigate(href)
      }}
    >
      {children}
    </MenuItem>
  )
}
