interface Props {
  open: boolean
}

export function SubMenuExpandIcon({ open }: Props) {
  if (open) {
    return (
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="subMenu-expandIcon"
      >
        <path
          d="M1.01191 6.46114C0.771021 6.68865 0.391304 6.67781 0.163789 6.43692C-0.0637257 6.19603 -0.0528835 5.81631 0.188006 5.5888L5.58761 0.488983C5.81882 0.270612 6.18026 0.270598 6.41148 0.48895L11.812 5.58877C12.0529 5.81626 12.0637 6.19598 11.8362 6.43689C11.6087 6.67779 11.229 6.68867 10.9881 6.46117L5.9996 1.75037L1.01191 6.46114Z"
          fill="#212121"
        />
      </svg>
    )
  }
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="subMenu-expandIcon"
    >
      <path
        d="M0.538924 1.01191C0.311409 0.771021 0.322251 0.391304 0.563141 0.163789C0.80403 -0.0637257 1.18375 -0.0528835 1.41126 0.188006L6.51108 5.58761C6.72945 5.81882 6.72946 6.18026 6.51111 6.41148L1.41129 11.812C1.1838 12.0529 0.804083 12.0637 0.563176 11.8362C0.322268 11.6087 0.311396 11.229 0.538891 10.9881L5.24969 5.9996L0.538924 1.01191Z"
        fill="#212121"
      />
    </svg>
  )
}
